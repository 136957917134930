<template>
  <div class="modal absolute inset-0 z-50">
    <div class="modal-wrapper flex flex-col rounded bg-blue-700">
      <h1 class="modal-title m-2 text-center text-white font-bold">
        <slot name="title"></slot>
      </h1>
      <font-awesome-icon icon="times"
                         class="modal-close text-2xl text-white"
                         @click="$emit('closeDialog')"></font-awesome-icon>
      <div class="modal-content p-4 flex flex-col items-left overflow-y-auto rounded-b bg-blue-850">
        <slot name="content"></slot>
        <button class="w-full py-3 mt-3 rounded text-white font-semibold"
                :class="bgColor"
                @click="$emit('buttonClicked')">
          <slot name="button"></slot>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    okColor: {
      type: String,
      required: false,
      default: 'red',
    },
  },
  computed: {
    bgColor () {
      if (this.okColor === 'green') {
        return 'bg-green-500';
      } if (this.okColor === 'blue') {
        return 'bg-blue-600';
      }

      return 'bg-red-700';
    },
  },
};
</script>

<style scoped>
  .modal {
    background-color: rgba(0,0,0,0.7);
  }

  .modal-wrapper {
    display: grid;
    position: absolute;
    top: calc(50% + 1.5rem);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 94vw;
    max-height: 70vh;
    grid-template-columns: 3rem 1fr 3rem;
    grid-template-rows: 3rem 1fr;
    grid-template-areas: "modal-head-left modal-head-center modal-head-right"
                          "modal-main modal-main modal-main";
  }

  .modal-title {
    grid-area: modal-head-center;
    align-self: center;
    justify-self: center;
  }

  .modal-close {
    grid-area: modal-head-right;
    align-self: center;
    justify-self: center;
  }

  .modal-content {
    grid-area: modal-main;
  }
</style>
