

function formatFcCount (count, casus = 'nom') {
  const number = (count || 0).toString();
  const prelastDigit = number.slice(-2, -1);
  const lastDigit = number.slice(-1);

  if (prelastDigit && prelastDigit === '1') return `${number} карточек`;
  if (['1'].includes(lastDigit)) {
    if (casus === 'acc') return `${number} карточку`;
    return `${number} карточка`;
  }
  if (['2', '3', '4'].includes(lastDigit)) return `${number} карточки`;
  return `${number} карточек`;
}

function calculateReducedScore (sa, task) {
  if (task.kind === 'term') return 0;

  let score = 2;
  const saa = sa.split('');
  const caa = task.ans[0].toString().split('');

  const sl = saa.length;
  const cl = caa.length;
  if (sl < cl) {
    const filler = new Array(cl - sl).fill('0');
    saa.push(...filler);
  } else if (cl < sl) {
    const filler = new Array(sl - cl).fill('0');
    caa.push(...filler);
  }

  for (let i = 0; i < caa.length; i++) {
    if (caa[i] !== saa[i]) score -= 1;
    if (score < 1) break;
  }

  return score;
}

exports.formatFcCount = formatFcCount;
exports.calculateReducedScore = calculateReducedScore;
