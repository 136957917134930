<template>
  <div>
    <div class="mb-8 font-semibold text-white">
      <p>{{ motivation }}<span class="ml-1 text-blue-400 cursor-pointer">
              <font-awesome-icon icon="question-circle"
                                 @click="() => this.showExplanation = true"></font-awesome-icon>
            </span></p>
    </div>

    <OneButtonModal v-if="showExplanation"
                    :okColor="'green'"
                    @closeDialog="() => this.showExplanation = false"
                    @buttonClicked="() => this.showExplanation = false">
      <div slot="title"
           @click="$router.push({ name: 'PageYes' })">Успех и карточки</div>
      <div slot="content" class="text-white">
        <h2 class="text-xl font-bold">Зачем поступать в универ?</h2>
        <p class="mt-1">Ты учишь биологию и химию, чтобы поступить в хороший универ.
          А в чем ценность "хорошего универа"? Не в том, чтобы дать диплом с профессией: половина людей работают по другой специальности.
          Ценность любого универа — это будущие сокурсники и преподаватели. Чем выше проходной балл — тем умнее
          и устремленнее к целям будут люди вокруг тебя. И все 4–6 лет обучения вы будете общаться практически каждый день. (Еще у крутых
          универов есть доступ к более крутым преподавателям, стажировкам и оборудованию).</p>
        <h2 class="mt-8 text-xl font-bold">Среда определяет будущее</h2>
        <p class="mt-1">Правильное окружение будет мотивировать на постоянное движение вперед + давать возможности
          реализовать себя, как только появятся желание + даст связи с толковыми студентами и преподавателями.</p>
        <h2 class="mt-8 text-xl font-bold">Усилия сегодня — среда завтра</h2>
        <p class="mt-1">Чем выше окажутся твои баллы на экзаменах — тем к лучшим окружениям ты получишь доступ.
          Ты точно сможешь набирать 90+ по биологии и химии, если будешь полностью выполнять ДЗ и решать карточки так,
          как подсказывает приложение. Проверено и доказано 😉</p>
        <p v-if="cardsLeft > 0"
           class="mt-6 mb-2">Сегодня осталось решить еще {{ formatFcCount(cardsLeft, 'acc') }}... 🧗</p>
        <p v-else class="mt-6 mb-2">Норма карточек на сегодня решена — отличная работа! 🥇</p>
      </div>
      <div slot="button">Понятно :)</div>
    </OneButtonModal>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import OneButtonModal from '@/components/OneButtonModal';
import { formatFcCount } from '@/assets/functions';

export default {
  components: { OneButtonModal },
  data () {
    return {
      messages: [
        'Чтобы через 5 лет выбирать работу (а не соглашаться на то, что есть)',
        'Чтобы через 7 лет купить собственную машину',
        'Чтобы через 5 лет работать в интересной лаборатории с новым оборудованием',
        'Чтобы через 2 года бесплатно ездить в другие города по студенческим олимпиадам и конкурсам',
        'Чтобы через 2 года учиться с умными ребятами, которые любят жизнь,',
        'Чтобы через 5 лет жить в гармонии, затрачивая эмоции на то, что интересно,',
        'Чтобы через 6 лет совершить научное открытие',
        'Чтобы через 5 лет иметь возможность дать дельный совет по своей области',
        'Чтобы через 2 года находиться в компании однокурсников, которым интересен мир вокруг,',
        'Чтобы через 10 лет претворять собственные идеи в жизнь силой десятков умов',
        'Чтобы через 15 лет купить комфортную квартиру близко от центра',
        'Чтобы через 4 года перестать брать деньги у родителей и начать жить на свои',
        'Чтобы через 5 лет заниматься делом, которое будет приносить удовольствие и приятный доход,',
        'Чтобы через 4 года оказаться на стажировке в Оксфорде',
        'Чтобы через 4 года выступать на конференции в Париже',
        'Чтобы через 15 лет быть самым крутым одноклассником на встрече одноклассников',
        'Чтобы через 10 лет ясно видеть свое призвание и смысл действий каждого дня',
        'Чтобы через 20 лет обеспечивать своим родителям достойную жизнь на пенсии',
        'Чтобы через 10 лет иметь отличную зарплату при свободном графике',
        'Чтобы через 10 лет испытывать впечатления красоты реального мира, а не их замену кинцом, мемами и играми,',
      ],
      showExplanation: false,
    };
  },
  computed: {
    ...mapState({
      cardsDue: state => state.stats.due,
      cardsDone: state => state.stats.done,
    }),
    cardsLeft () { return Math.max(0, Math.min(80, this.cardsDue) - this.cardsDone); },
    motivation () {
      if (this.cardsLeft === 0 && this.cardsDone === 0)
        return 'Карточек к решению пока нет.';
      if (this.cardsLeft === 0)
        return `За сегодня ${this.cardsDone % 10 === 1 ? 'решена' : 'решено'} уже ${this.formatFcCount(this.cardsDone)} — отличная работа!`;
      const start = this.messages[Math.floor(this.messages.length * Math.random())];
      const end = ` — сегодня надо решить еще ${this.formatFcCount(this.cardsLeft, 'acc')}.`;
      return start + end;
    },
  },
  methods: {
    formatFcCount: formatFcCount,
  },
};
</script>

<style>

</style>
