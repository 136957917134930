<template>
  <div>
    <navbar-top :left="currentView === 'mainMenu' ? '' : 'back'"
                middle="logo"
                right="logout"
                @cl="closeBranch"
                @cr="logout" />

    <div class="canvas fixed inset-0 pt-14 bg-blue-850 overflow-y-auto"
         v-touch:swipe.right="() => closeBranch()">
      <div v-if="dataLoaded"
           class="wrapper">
        <div class="flex flex-col p-6 sm:px-10">
          <div v-if="currentView === 'mainMenu'">
            <motivation />
            <div class="menu-button big-button text-center cursor-pointer"
                 @click="solveDose">
              <p class="text-center text-2xl font-bold">Все темы</p>
              <div class="flex mt-2 justify-between text-base font-light">
                <p>по 20 карточек</p>
                <p>за сегодня: {{ dosesDone }}/4</p>
              </div>
            </div>
            <button class="menu-button badge"
                    :data-badge="tree[currentBranch].due"
                    v-touch:touchhold.prevent="() => solveAll(currentBranch)"
                    @click="currentView = 'solveMenu'">Решать конкретную тему</button>
            <button class="menu-button"
                    @click="currentView = 'lookMenu'">Найти карточку</button>
            <button class="menu-button"
                    @click="currentView = 'trainMenu'">Тренажер ЕГЭ</button>
          </div>
          <div v-else-if="currentView === 'solveMenu'">
            <button v-for="child in childrenByTitle"
                    :key="child.key"
                    :class="child.locked ? 'locked' : ''"
                    class="menu-button badge"
                    :data-badge="child.due"
                    @click="openBranch(child.key)"
                    v-touch:touchhold.prevent="() => solveAll(child.key)">{{ child.title }}
            </button>
          </div>
          <div v-else-if="currentView === 'lookMenu'">
            <button v-for="child in childrenByTitle"
                    :key="child.key"
                    :class="child.locked ? 'locked' : ''"
                    class="menu-button window-badge"
                    :data-badge="child.total"
                    @click="openBranch(child.key)">{{ child.title }}
            </button>
          </div>
          <div v-else-if="currentView === 'trainMenu'">
            <button class="menu-button"
                    @click="$router.push('/ege/create?subject=bio')">Биология</button>
            <button class="menu-button"
                    @click="$router.push('/ege/create?subject=chem')">Химия</button>
          </div>
        </div>
      </div>
      <menu-spinner v-else></menu-spinner>
    </div>


    <keypress key-event="keyup" :multiple-keys="keyboard.dose" @success="solveDose" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Keypress from 'vue-keypress';
import NavbarTop from "@/components/NavbarTop";
import MenuSpinner from '@/components/MenuSpinner.vue';
import Motivation from '@/components/Motivation';

export default {
  components: { NavbarTop, MenuSpinner, Motivation, Keypress },
  data () {
    return {
      dataLoaded: false,
      currentView: 'mainMenu',
      keyboard: {
        dose: [
          { keyCode: 13, modifiers: [], preventDefault: false }, // ENTER
        ],
      },
    };
  },
  computed: {
    ...mapState({
      tree: state => state.stats.tree,
      currentBranch: state => state.stats.currentBranch,
      cardsDone: state => state.stats.done,
    }),
    childrenByDue () {
      return this.tree[this.currentBranch].children
          .map(c => this.tree[c])
          .sort((a,b) => b.due - a.due || a.title.localeCompare(b.title, undefined, { numeric: true }));
    },
    childrenByTitle () {
      return this.tree[this.currentBranch].children
          .map(c => this.tree[c])
          .sort((a,b) => a.title.localeCompare(b.title, undefined, { numeric: true }));
    },
    dosesDone () { return Math.floor(this.cardsDone / 20); },
  },
  created () {
    // stuff for app updating
    document.addEventListener(
        'serviceWorkerUpdated', (e) => {
          console.log(e);
          console.log(e.detail);
          const registration = e.detail;
          registration.waiting.postMessage('skipWaiting');
        }, { once: true },
    );


    Promise.all([
      this.$store.dispatch('stats/buildDeckTree'),
      this.$store.dispatch('stats/getCounts'),
    ]).then(() => {
      this.dataLoaded = true;
    });
  },
  methods: {
    solveDecks ({ deckIds, onlyDue }) { return this.$router.push(`/solve/${deckIds}?onlyDue=${onlyDue}`); },
    inspectDeck (deckId) { return this.$router.push(`/deck/${deckId}`); },
    solveDose () { return this.$router.push('/solve/daily'); },
    openBranch (key) {
      const smallerBranch = this.tree[key];
      const hasDue = !!smallerBranch.due;

      if (smallerBranch.locked) return false;

      if (smallerBranch.children.length === 0) {
        if (this.currentView === 'solveMenu')
          return this.solveDecks({
            deckIds: [smallerBranch.key],
            onlyDue: hasDue,
          });
        else if (this.currentView === 'lookMenu')
          return this.inspectDeck(smallerBranch.key);

        console.log('Purrr');
        return false;
      }

      return this.$store.commit('stats/setCurrentBranch', smallerBranch.key);
    },
    closeBranch () {
      if (this.currentView === 'trainMenu') return this.currentView = 'mainMenu';
      const largerBranchKey = this.tree[this.currentBranch].parent;
      if (!largerBranchKey) return this.currentView = 'mainMenu';
      return this.$store.commit('stats/setCurrentBranch', largerBranchKey);
    },
    solveAll (key) {
      const smallerBranch = this.tree[key];
      const hasDue = !!smallerBranch.due;

      function extractDeckIds (tree, key, ids = []) {
        if (tree[key].locked) return ids;

        if (tree[key].children.length === 0) {
          ids.push(key);
          return ids;
        }
        for (const child of tree[key].children)
          extractDeckIds(tree, child, ids);

        return ids;
      }

      const deckIds = extractDeckIds(this.tree, smallerBranch.key);
      return this.solveDecks({ deckIds, onlyDue: hasDue });
    },
    logout () {
      this.dataLoaded = false;
      return this.$store.dispatch('auth/logout')
          .then(() => {
            if (navigator.serviceWorker.controller)
              navigator.serviceWorker.controller.postMessage('clearAuth');
            setTimeout(() => this.$router.push('/login'), 500);
          });
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: block;
  margin: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 639px) {
    width: 100%;
  }

  @media (min-width: 640px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.menu-button {
  @apply w-full my-2 p-3 bg-blue-600 rounded-xl font-bold text-lg text-white select-none;
}

.big-button {
  background: #48bb78 !important;
}

.badge {
  position:relative;
}

.badge[data-badge]:after {
  min-width: 0.75rem;
  min-height: 0.75rem;
  font-size: 0.8rem;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  transform-origin: right;
  transform: translate3d(50%, -50%, 0);

  display: inline-block;
  position: absolute;
  margin: 0;
  padding: .25rem .5rem;
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  background-clip: padding-box;
  background: red;
  border-radius: 100px;
  box-shadow: 0 0 0 1px white;
  color: white;
  content: attr(data-badge);
}

.window-badge {
  position:relative;
}

.window-badge[data-badge]:after {
  min-width: 0.75rem;
  min-height: 0.75rem;
  font-size: 0.8rem;
  font-weight: bold;
  top: 50%;
  right: 0.75rem;
  bottom: auto;
  left: auto;
  transform-origin: right;
  transform: translate3d(0, -50%, 0);

  display: inline-block;
  position: absolute;
  margin: 0;
  padding: .5rem .6rem;
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  background-clip: padding-box;
  border-radius: 5px;
  background: #1E40AF;
  content: attr(data-badge);
}

.badge[data-badge="0"]:after {
  display: none;
}

.locked:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  content: "";
  border-radius: inherit;
  box-shadow: rgba(0, 0, 0, 0.02) 0 0 20px 8px;
}
</style>
