<template>
  <div class="loader-container" :class="local ? '' : 'fullpage'">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  props: {
    local: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
$blue-900: #2A4365;

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullpage {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
  height: 100vh;
  width: 100vw;
}

.loader {
  border: 5px solid #1d3372;
  border-top: 5px solid #FFFFFF;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
