<template>
  <div class="fixed inset-x-0 top-0 h-14 bg-blue-750 z-20 select-none">
    <div class="header-wrapper">
      <div class="flex justify-center items-center"
           @click="$emit('cl')">
        <font-awesome-icon v-if="left === 'back'"
                           icon="arrow-left"
                           class="text-white text-2xl"></font-awesome-icon>
      </div>

      <div v-if="dataLeft.addButton"
           class="flex justify-center items-center"
           @click="$emit('cla')">

      </div>
      <div v-else></div>

      <div class="flex justify-center items-center"
           @click="$emit('cm')">
        <object v-if="middle === 'logo'"
                data="/fc_logo.svg"
                class="h-9"></object>
        <slot v-else-if="['counter', 'title'].includes(middle)"></slot>
      </div>

      <div v-if="dataRight.addButton"
           class="flex justify-center items-center"
           @click="$emit('cra')">
        <font-awesome-icon v-if="dataRight.subject === 'chem'"
                           icon="table"
                           class="text-white text-2xl"></font-awesome-icon>
      </div>
      <div v-else></div>

      <div class="flex justify-center items-center"
           @click="$emit('cr')">
        <font-awesome-icon v-if="right === 'logout'"
                           icon="sign-out-alt"
                           class="text-white text-2xl"></font-awesome-icon>
        <font-awesome-icon v-if="right === 'flag'"
                           icon="flag"
                           class="text-2xl text-white"
                           :class="dataRight.flagged ? '' : 'opacity-25'"></font-awesome-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    left: String,
    middle: String,
    right: String,
    dataLeft: {
      type: Object,
      default () { return {}; },
    },
    dataMiddle: {
      type: Object,
      default () { return {}; },
    },
    dataRight: {
      type: Object,
      default () { return {}; },
    }
  },
  data () {
    return {

    };
  },
}
</script>

<style scoped lang="scss">
.header-wrapper {
  display: grid;
  margin: auto;
  width: 100%;
  height: 100%;
  grid-template-columns: 3.5rem 3.5rem 1fr 3.5rem 3.5rem;
  grid-template-rows: 1fr;
  grid-template-areas: "header-left header-left-add header-logo header-right-add header-right";
  grid-gap: 0;

  @media (max-width: 639px) {
    width: 100%;
  }
  @media (min-width: 640px) {
    width: 75%;
  }
  @media (min-width: 1024px) {
    width: 50%;
  }
}
</style>
